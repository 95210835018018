@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
* {
  overflow: visible;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

.nanobar .bar {
  background: rgb(255, 255, 255);
  border-radius: 1px;
  box-shadow: 0 0 10px #59d;
  height: 5px;
}

body {
  background-color: rgb(27, 27, 27);
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}

#root {
  display: grid;
  height: 100vh;
  place-items: center;
  background-color: rgb(27, 27, 27);
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.base-timer {
  transform: scale(0.8);
  position: relative;
  width: 300px;
  height: 300px;
  margin-top: 0;
  /*noinspection CssInvalidFunction*/
  box-shadow: 0px 40px 50px 0 rgba(0, 0, 0, 0.25); /* IntelliJ yells at me even though its valid */
  padding: 2em;
  padding-top: 5em;
  border-radius: 1em;
}

.base-timer-svg {
  transform: scaleX(-1);
}

.base-timer-circle {
  fill: none;
  stroke: none;
}

.base-timer-path-elapsed {
  stroke-width: 3px;
  stroke: rgb(22, 22, 22);
}

.base-timer-path-remaining {
  stroke-width: 3px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 2.5s cubic-bezier(0.18, 0.89, 0.04, 0.97) all;
  -webkit-transition: 2.5s cubic-bezier(0.18, 0.89, 0.04, 0.97) all;
  fill-rule: nonzero;
  stroke: rgb(0, 255, 140);
  filter: drop-shadow(0 0 0.75rem rgba(0, 255, 140, 0.4196078431));
}

.timer-countdown {
  position: absolute;
  width: 300px;
  height: 300px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  margin-top: 1.5em;
}

#current_period {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  margin-top: 6.3em;
  font-weight: bold;
}

#current_day {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3.5em;
}

.stingers {
  background-color: rgb(31, 31, 31);
  text-align: center;
  padding: 1em;
  box-shadow: 0px 40px 50px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.3em;
  margin-top: -20em;
  transform: scale(0.8);
}

.stingers h3 {
  margin-bottom: 0.3em;
}

.stingers p {
  color: rgb(196, 196, 196);
  font-weight: bold;
}

.invisible {
  display: none;
}

@media only screen and (min-width: 600px) {
  .base-timer {
    transform: scale(1);
  }
  .base-timer {
    margin-top: 0em;
  }
  .stingers {
    margin-top: 0em;
    transform: scale(1);
  }
}
.loading {
  transform: scale(0.3);
  position: absolute;
}
.loading .lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading .lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.loading .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.loading .lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.loading .lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.loading .lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.loading .lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.loading .lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.loading .lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.loading .lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.loading .lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.loading .lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.loading .lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.loading .lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.loading .lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.outOfSync {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  margin-bottom: 2em;
  margin-right: 2.44em;
}
.outOfSync button {
  cursor: pointer;
  background-color: #2f2f2f;
  border: none;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 0.2em !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.685);
  transition: all 0.3s ease-in-out;
  color: white;
  border-radius: 0.1em;
  font-weight: bolder;
  text-transform: none !important;
}
.outOfSync button:hover {
  background-color: rgb(39, 39, 39);
}
.outOfSync button:active {
  background-color: rgb(34, 34, 34);
  transform: scale(0.98);
}
.outOfSync button img {
  margin-right: 0.7em;
}

.toggle-notifications {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  margin-bottom: 2em;
  margin-left: 2.44em;
}
.toggle-notifications .toggle-notifications-button {
  cursor: pointer;
  background-color: #2f2f2f;
  border: none;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 0.2em !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.685);
  transition: all 0.3s ease-in-out;
  color: white;
  border-radius: 0.1em;
  font-weight: bolder;
  text-transform: none !important;
}
.toggle-notifications .toggle-notifications-button:hover {
  background-color: rgb(39, 39, 39);
}
.toggle-notifications .toggle-notifications-button:active {
  background-color: rgb(34, 34, 34);
  transform: scale(0.98);
}
.toggle-notifications .toggle-notifications-button img {
  margin-right: 0.7em;
}
.toggle-notifications .toggle-notifications-button:disabled {
  opacity: 0.2 !important;
  cursor: not-allowed !important;
}

.no-school {
  padding: 1em;
  display: flex;
  justify-content: center;
  text-align: center;
}

.error {
  padding: 1em;
  display: flex;
  justify-content: center;
  text-align: center;
}

.timestamp {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.733);
  padding: 1em;
  margin-top: 1em;
  margin-left: 1em;
}/*# sourceMappingURL=style.css.map */